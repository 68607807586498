/** @format */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
}
@font-face {
  font-family: "eurostileunicaseltpro";
  src: url(./font/eurostileunicaseltpro-maisfontes.2928/eurostileunicaseltpro.otf);
}
p {
  font-family: "Inter", sans-serif !important;
  margin-bottom: 0px !important;
}
.font-para {
  font-family: "Inter", sans-serif !important;
}
.font-head {
  font-family: "eurostileunicaseltpro";
}
::selection {
  background: #fff;
  color: #000;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #000;
}
a {
  text-decoration: none !important;
}
body {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  overflow-x: hidden !important;
  position: relative !important;
  background: linear-gradient(
    89deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(87, 84, 108, 0.47) 20%,
    rgba(87, 84, 108, 0.54) 81%,
    rgba(255, 255, 255, 1) 100%
  );
}
.navbar-toggler {
  outline: none !important;
  box-shadow: none !important;
}
.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #000;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar .navbar-toggler-icon:after {
  top: 8px;
}
p {
  margin-bottom: 0px !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar-toggler {
  border: none !important;
}
.collapse {
  visibility: visible !important;
}

.i::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  opacity: 20%;
  background: white;
  /*   background: #3B82F6; */
  /* Centering */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.i:hover:before {
  animation: anim-in 0.7s forwards ease-out;
}
.card_main {
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: auto 100%;
  margin: 0px 0px 107px;
  // padding: 32px;
  background: linear-gradient(95.64deg, #57546c4d -117.13%, #f1f1f877 89.73%),
    url("../src/media/images/border.svg") no-repeat !important;
  border-radius: 24px;
}
@keyframes anim-in {
  100% {
    opacity: 0%;
    border-radius: 0;
    width: 600px;
    height: 600px;
  }
  0% {
    width: 0px;
    height: 0px;
    border-radius: 100%;
    opacity: 20%;
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.borde {
  border: 1px solid rgb(20, 20, 20);
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.selected {
  background-color: transparent !important;
  transition: all 2s ease-in-out !important;
}
.btn_buy {
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  font-family: "eurostileunicaseltpro" !important;
  color: #000;
  transition: all 2s ease-in-out !important;

  padding: 14px;
  width: 100%;
  text-align: center;
}
.btn_sell {
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  transition: all 2s ease-in-out !important;

  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  font-family: "eurostileunicaseltpro" !important;
  color: #000;
  padding: 14px;
  width: 100%;
  text-align: center;
}
